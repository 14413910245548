<script>
import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  data() {
    return {
      rule: [],
      dictCodeList: {},
    };
  },
  components: {
  },
  async mounted() {
    const rule = await this.getBeforFormRule('dms_discount_total_adjust');
    const dictCodeList = [];
    rule.forEach((v) => {
      const rowData = v;
      const { isDisabled, isVisible } = this.judgeMoadlType(rowData.field);
      rowData.type = isVisible ? 'hidden' : rowData.type;
      if (rowData.dictCode) {
        dictCodeList.push(rowData.dictCode);
      }
      rowData.props = {
        ...rowData.prop,
        disabled: isDisabled,
      };

      if (rowData.field === 'payType') {
        rowData.value = '0';
        rowData.props = {
          ...rowData.prop,
          disabled: true,
        };
      }
      if (rowData.field === 'files') {
        rowData.props = {
          ...rowData.prop,
          showFileList: true,
          uploadType: 'file',
          listType: 'text',
          fileMsg: '只能上传pdf/word/excel/ppt/png/jpg文件',
          accept: '.pdf,.doc,.docx,.xlsx,.xls,.ppt,.pptx,.png,.jpg',
        };
      }
      if (rowData.field === 'adjustType') {
        rowData.on = {
          ...rowData.on,
          change: (val) => {
            const adjustFee = this.getRule('adjustFee');
            adjustFee.props.disabled = false;
            if (val === '11') {
              adjustFee.props.max = this.formConfig.selectRow[0].balance;
            } else {
              delete
              adjustFee.props.max;
            }
            this.changeAdjustFee(adjustFee.value, val);
          },
        };
      }
      // 调整金额控件
      if (rowData.field === 'adjustFee') {
        rowData.props = {
          ...rowData.props,
          min: 0,
        };
        rowData.on = {
          ...rowData.on,
          input: this.changeAdjustFee,
        };
      }
      // 冻结金额控件
      if (rowData.field === 'frozenFee') {
        rowData.props = {
          ...rowData.props,
          min: 0,
          max: this.formConfig.selectRow[0].balance,
        };
        rowData.on = {
          ...rowData.on,
          input: this.changeFrozenFee,
        };
      }
      // 解冻金额控件
      if (rowData.field === 'frozenFee8') {
        const { frozenFee, unfrozenFee } = this.formConfig.selectRow[0];
        rowData.props = {
          ...rowData.props,
          min: 0,
          max: frozenFee - unfrozenFee,
        };
        rowData.on = {
          ...rowData.on,
          input: this.changeUnFrozenFee,
        };
      }
      if (rowData.field === 'fee' || rowData.field === 'frozenFee3' || rowData.field === 'frozenFee7') {
        rowData.validate = [{
          type: 'number', min: 0, message: '最小为0',
        }];
      }
      return rowData;
    });
    this.rule = rule;
    this.reload(rule);
    const selectRow = JSON.parse(JSON.stringify(this.formConfig.selectRow[0]));
    selectRow.payType = '0';
    selectRow.files = selectRow.files || [];
    selectRow.remarks = '';
    if (this.formConfig.btnCode === 'frozen') {
      // 设置冻结状态数据
      selectRow.frozenFee = 0;
      selectRow.frozenFee1 = selectRow.balance;
      selectRow.files = selectRow.files || [];
      selectRow.remarks = '';
    } else if (this.formConfig.btnCode === 'thaw') {
      selectRow.frozenFee5 = selectRow.frozenFee;
      selectRow.files = selectRow.files || [];
      selectRow.remarks = '';
    } else if (this.formConfig.btnCode === 'view') {
      selectRow.frozenFee5 = selectRow.frozenFee;
      selectRow.files = selectRow.files || [];
      selectRow.remarks = '';
    }
    this.setValue(selectRow);
    this.setDictCode(dictCodeList);
    this.afterReload();
  },
  methods: {
    afterReload() {

    },
    // // 获取解冻信息
    // getThawInfo(){
    //    request.post('/mdm/mdmdictdata/findFrozenItemPageByConditions', {}).then((res) => {
    //     this.dictCodeList = res.result;
    //     this.getDefaultIndexDictCode();
    //   });
    // },
    // 扣减顺序去默认值
    getDefaultIndexDictCode() {
      this.setValue({
        indexDictCode: this.dictCodeList.fee_reduce_redtrick_rule[0].dictCode,
      });
    },
    // 获取列表中包含的数据字典
    setDictCode(list) {
      request.post('/mdm/mdmdictdata/batchDictSelect', list).then((res) => {
        this.dictCodeList = res.result;
        this.getDefaultIndexDictCode();
      });
    },
    // 获取列表中包含的数据字典
    getDictCode(val, code) {
      const dict = this.dictCodeList[code].find((v) => v.dictCode === val);
      return dict ? dict.dictValue : val;
    },
    // 判断当前弹框的状态是调整或者冻结或者解冻
    judgeMoadlType(field) {
      let isVisible = true;
      let isDisabled = true;
      switch (field) {
        case 'indexDictCode': isVisible = false; break;
        case 'cusName': isVisible = false; break;
        case 'cusCode': isVisible = false; break;
        case 'saleCompanyName': isVisible = false; break;
        case 'remarks': isVisible = false; isDisabled = false; break;
        case 'files': isVisible = false; isDisabled = false; break;
        case 'payType': isVisible = false; break;
        default: isVisible = true; break;
      }
      if (this.formConfig.btnCode === 'total_adjust' && isVisible === true) {
        switch (field) {
          case 'adjustType': isVisible = false; isDisabled = false; break;
          case 'balance': isVisible = false; break;
          case 'adjustFee': isVisible = false; break;
          case 'fee': isVisible = false; break;
          case 'adjustReason': isVisible = false; isDisabled = false; break;
          default: isVisible = true; break;
        }
      } else if (this.formConfig.btnCode === 'frozen' && isVisible === true) {
        switch (field) {
          case 'frozenFee1': isVisible = false; break;
          case 'frozenFee': isVisible = false; isDisabled = false; break;
          case 'frozenFee3': isVisible = false; break;
          case 'frozenReason': isVisible = false; isDisabled = false; break;
          default: isVisible = true; break;
        }
      } else if (this.formConfig.btnCode === 'thaw' && isVisible === true) {
        switch (field) {
          case 'frozenFee5': isVisible = false; break;
          case 'unfrozenFee': isVisible = false; break;
          case 'frozenFee7': isVisible = false; break;
          case 'frozenFee8': isVisible = false; isDisabled = false; break;
          case 'frozenFee9': isVisible = false; isDisabled = false; break;
          default: isVisible = true; break;
        }
      } else if (this.formConfig.btnCode === 'view' && isVisible === true) {
        switch (field) {
          case 'frozenFee5': isVisible = false; break;
          case 'unfrozenFee': isVisible = false; break;
          case 'frozenFee7': isVisible = false; break;
          case 'frozenFee8': isVisible = false; break;
          case 'frozenFee9': isVisible = false; break;
          default: isVisible = true; break;
        }
      }
      return { isVisible, isDisabled };
    },
    // 提交
    submit() {
      if (!this.getFormData()) return false;
      const params = {
        ...this.formConfig.selectRow[0],
        ...this.getFormData(),
        uniqueDictCode: 'unique_rebate_code',
      };
      delete params.list;
      let path = '/dms/dms/m/rebateFeePool/wrapperAdjust';
      if (this.formConfig.btnCode === 'frozen') {
        params.adjustType = '8';
        params.adjustFee = params.frozenFee;
        params.adjustReason = params.frozenReason;
      } else if (this.formConfig.btnCode === 'thaw') {
        params.adjustType = '9';
        params.adjustFee = params.frozenFee8;
        path = '/dms/dms/m/rebateFeePool/unfrozenItem';
      }
      request
        .post(path, params)
        .then((res) => {
          if (res.success) {
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
    },
    // 调整计算
    changeAdjustFee(val, type) {
      const balance = this.getFieldValue('balance');
      const adjustType = type || this.getFieldValue('adjustType');
      let res = 0;
      if (adjustType === '10') {
        res = this.accAdd(balance, val);
      } else if (adjustType === '11') {
        res = this.accSub(balance, val);
      }
      this.setValue({
        fee: res,
      });
    },
    // 冻结计算
    changeFrozenFee(val) {
      const usedFee = this.getFieldValue('frozenFee1');
      let res = 0;
      res = this.accSub(usedFee, val);
      this.setValue({
        frozenFee3: res,
      });
    },

    // 解冻计算
    changeUnFrozenFee(val) {
      const usedFee = this.getFieldValue('frozenFee5');
      const unfrozenFee = this.getFieldValue('unfrozenFee');
      let res = 0;
      res = this.accSub(this.accSub(usedFee, unfrozenFee), val);
      this.setValue({
        frozenFee7: res,
      });
    },
    // 两个浮点数求和
    accAdd(num1, num2) {
      let r1;
      let r2;
      let m = 0;
      try {
        r1 = num1.toString().split('.')[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = num2.toString().split('.')[1].length;
      } catch (e) {
        r2 = 0;
      }
      // eslint-disable-next-line no-restricted-properties
      m = Math.pow(10, Math.max(r1, r2));
      // return (num1*m+num2*m)/m;
      return Math.round(num1 * m + num2 * m) / m;
    },
    // 两个浮点数相减
    accSub(num1, num2) {
      let r1;
      let r2;
      let m = 0;
      try {
        r1 = num1.toString().split('.')[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = num2.toString().split('.')[1].length;
      } catch (e) {
        r2 = 0;
      }
      // eslint-disable-next-line no-restricted-properties
      m = Math.pow(10, Math.max(r1, r2));
      // eslint-disable-next-line no-restricted-properties
      const n = (r1 >= r2) ? r1 : r2;
      return (Math.round(num1 * m - num2 * m) / m).toFixed(n);
    },
  },
};
</script>
