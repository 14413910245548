<!--
 * @Author: yzr
 * @Date: 2020-11-26 16:28:59
 * @LastEditors: yzr
 * @LastEditTime: 2020-11-29 16:34:14
-->
<template>
  <div @click="openModal">
    <!-- 弹框 -->
    <el-input
      placeholder="客户名称"
      :value="name || value"
      :disabled="disabled"
    ></el-input>
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import SelectConfig from '../../../../../../../components/select_org_cus_ter/select_config.vue';

const cusColumn = [
  { field: 'customerCode', title: '客户编码' },
  { field: 'customerName', title: '客户名称' },
];
export default {
  name: 'CheckCustomer',
  components: {
    SelectConfig,
  },
  props: {
    value: String,
    disabled: Boolean,
  },
  data() {
    return {
      modalConfig: {}, // 弹框信息
      firstColumn: cusColumn,
      name: '',
    };
  },
  methods: {
    onGetSelect(val) {
      this.name = val[0].customerName;
      this.$emit('setcustomerName', val[0]);
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = list2;
      list1.forEach((v1) => {
        let notRepeat = true;
        const rowData = v1;
        for (const v2 in list2) {
          if (v1[key] === list2[v2][key]) {
            notRepeat = true;
            return false;
          }
        }
        if (notRepeat) {
          list.push(rowData);
        }
        return rowData;
      });
      return list;
    },
    // 打开弹窗
    openModal() {
      const params = {
        functionCode: 'dms_discount_cost_pool_customer',
        tableUrl: '/mdm/mdmCustomerMsgController/page',
        data: [],
        selectionList: [],
        idKey: 'customerCode',
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
  },
};
</script>

<style lang="less" scoped>
</style>
